import { useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import Container from "reactstrap/lib/Container";

import useApiFetch from "@hooks/useApiFetch";
import { formatDate } from "@lib/date";

import ProductFactorForm from "./ProductFactorForm";

const EditProductFactor = (props) => {
  const {
    match: {
      params: { id: productID, productFactorID },
    },
  } = props;

  const editProductFactorForm = useForm({
    mode: "onChange",
  });

  const { get, isPending } = useApiFetch();
  const [productFactor, setProductFactor] = useState({});

  const { reset, getValues } = editProductFactorForm || {};

  const getProductFactor = useCallback(async () => {
    if (!productFactorID) {
      setProductFactor(null);
    }
    const response = await get(`products/productFactors/${productFactorID}`);
    setProductFactor(response);
  }, [productFactorID]);

  useEffect(() => {
    getProductFactor();
  }, [getProductFactor]);

  useEffect(() => {
    if (productFactor?.factorID) {
      reset({
        ...getValues(),
        ...productFactor,
        lastUpdatedOn: formatDate(productFactor?.lastUpdatedOn, "MM/DD/YY"),
        deferralYears: String(productFactor?.deferralYears),
        jointPercent: String(productFactor?.jointPercent),
      });
    }
  }, [productFactor?.factorID]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3">Product Factor Detail</h3>
      <ProductFactorForm
        form={editProductFactorForm}
        productFactorID={productFactorID}
        loadingResponse={isPending}
        productID={productID}
      />
    </Container>
  );
};

export default EditProductFactor;

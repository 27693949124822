import { useContext, useState, useMemo } from "react";

import { Controller } from "react-hook-form";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { connectTheme } from "@contexts/Context";

const FormLabel = ({ label, required }) => {
  return (
    label && (
      <div className="form__form-group-label">
        {label}
        {required && <span className="text-danger"> *</span>}
      </div>
    )
  );
};

const MultiSelect = ({
  name = "",
  label = "",
  required = false,
  data = [],
  control,
  rules,
  disabled = false,
  isMulti = false,
  removeNone = false,
  placeholder = "",
  variant = "form",
}) => {
  const { isLight } = useContext(connectTheme);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const selectOptions = useMemo(() => {
    const baseOptions = removeNone ? data : [{ label: "", value: "" }, ...data];
    return isMulti && !removeNone
      ? [{ label: "All", value: "all" }, ...data]
      : baseOptions;
  }, [isMulti, removeNone, data]);

  const itemClassName = `select_item ${isLight ? "bg_light" : "bg_dark"}`;
  const selectClassName = `react-select select select_menu ${
    isLight ? "border_light color_light" : "border_dark color_dark"
  }`;
  const selectWrapperClass =
    variant === "table"
      ? "table__search-input table__search mb-0"
      : "form__form-group-field";

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={isMulti ? [] : ""}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        const handleSelect = (option) => {
          if (isMulti) {
            if (option === "all") {
              onChange(
                value.length === data.length
                  ? []
                  : data.map((item) => item.value)
              );
            } else {
              const newValue = value.includes(option)
                ? value.filter((item) => item !== option)
                : [...value, option];
              onChange(newValue);
            }
          } else {
            onChange(option);
          }
        };

        const isSelected = (option) =>
          isMulti
            ? option === "all"
              ? value.length === data.length
              : value.includes(option)
            : value === option;

        return (
          <>
            <FormLabel label={label} required={required} />
            <div className={selectWrapperClass}>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                disabled={disabled}
              >
                <DropdownToggle className={selectClassName} caret>
                  {isMulti
                    ? value.length > 0
                      ? `${value.length} Selected`
                      : placeholder
                    : value || placeholder}
                </DropdownToggle>
                <DropdownMenu>
                  {selectOptions?.length
                    ? selectOptions.map(
                        ({ value: optionValue, label: optionLabel }) => (
                          <DropdownItem
                            key={optionValue}
                            toggle={!isMulti}
                            onClick={() => handleSelect(optionValue)}
                            className={`${itemClassName} ${
                              !isMulti && isSelected(optionValue)
                                ? "bg-secondary text-white"
                                : ""
                            }`}
                          >
                            {isMulti && (
                              <input
                                type="checkbox"
                                checked={isSelected(optionValue)}
                                onChange={() => handleSelect(optionValue)}
                                className="mr-2"
                              />
                            )}
                            {optionLabel || <span>&nbsp;</span>}
                          </DropdownItem>
                        )
                      )
                    : []}
                </DropdownMenu>
              </Dropdown>
              {errors?.[name] && (
                <span className="form__form-group-error">
                  {errors[name]?.message}
                </span>
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default MultiSelect;

import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";

import { Spinner, Table } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import { formatDate, getRelativeDate } from "@lib/date";
import { formatFixedDigits } from "@lib/number";

import WorkflowsTableSearch from "./WorkflowsTableSearch";

const queryParams = [
  "searchInput",
  "wfType",
  "statusInput",
  "fromDate",
  "toDate",
  "page",
  "sortWFNumber",
  "sortWType",
  "sortWDate",
  "sortReAccountNumber",
  "pageSize",
];

const workflowLogsColumns = [
  {
    id: "workflowsLogDate",
    header: "Log Date",
    accessorFn: (row) => formatDate(row?.workflowsLogDate, "MM/DD/YY hh:mm A"),
  },
  {
    id: "workflowsLogStatus",
    header: "Status",
    accessorFn: (row) =>
      `${row?.workflowsLogStatus} / ${row?.workflowsLogSubStatus}`,
  },
  {
    id: "workflowsLogStatusMessage",
    header: "Message",
    accessorKey: "workflowsLogStatusMessage",
  },
];

const WorkflowLogTable = (subTableProps) => {
  const { workflowLogs = [] } = subTableProps.row.original || {};
  return (
    <Table columns={workflowLogsColumns} data={workflowLogs} hasNestedTable />
  );
};

const defaultFromDate = getRelativeDate(7, "days", "previous");
const defaultToDate = formatDate();

const WorkflowsTable = (props) => {
  const { refresh, setRefresh, isRequesting } = props || {};
  const history = useHistory();
  const { get, isPending } = useApiFetch();
  const { buildQueryParams, getQueryParams } = useQueryParams();

  const {
    page,
    sortWFNumber,
    sortWType,
    sortWDate,
    sortReAccountNumber,
    pageSize: pageSizeQuery,
    searchInput,
    wfType,
    statusInput,
    fromDate: queryFromDate,
    toDate: queryToDate,
  } = getQueryParams(queryParams);

  const [submitSearch, setSubmitSearch] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [pageSize, setPageSize] = useState(pageSizeQuery || pagination.length);
  const { offset, pageNumber } = getPagination(pageSize, page) || {};

  const form = useForm({
    defaultValues: {
      fromDate: queryFromDate || defaultFromDate,
      toDate: queryToDate || defaultToDate,
      searchWorkflow: searchInput,
      workflowType: wfType,
      workflowStatus: statusInput,
    },
  });

  const { getValues } = form;

  const { searchWorkflow, workflowType, workflowStatus, fromDate, toDate } =
    getValues();

  const { data = [], recordCount = 0 } = workflowData || {};

  const orderBy = useMemo(() => {
    if (sortWType)
      return `WorkflowTypes.WorkflowType ${sortWType.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    if (sortWFNumber)
      return `Workflows.WorkflowNumber ${sortWFNumber.toUpperCase()}, WorkflowsLog.LogDate ASC`;

    if (sortWDate)
      return `Workflows.WorkflowStartDate ${sortWDate.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    if (sortReAccountNumber)
      return `REAccounts.REAccountNumber ${sortReAccountNumber.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    return "";
  }, [sortWType, sortWFNumber, sortWDate, sortReAccountNumber]);

  const redirectUrl = useCallback(
    (workflowID = "") => {
      const queryPath = buildQueryParams({
        searchInput: searchWorkflow,
        wfType: workflowType,
        statusInput: workflowStatus,
        fromDate,
        toDate,
        sortWFNumber,
        sortWType,
        sortWDate,
        sortReAccountNumber,
        pageSize,
      });

      return `/workflows${
        workflowID ? `/${workflowID}/edit?from=${pageNumber}` : "?page=1"
      }&${queryPath}`;
    },
    [
      fromDate,
      pageNumber,
      pageSize,
      searchWorkflow,
      sortReAccountNumber,
      sortWDate,
      sortWFNumber,
      sortWType,
      toDate,
      workflowStatus,
      workflowType,
    ]
  );

  const getWorkflowsData = useCallback(async () => {
    const queryParams = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      OrderBy: orderBy,
      workflowType: workflowType,
      search: searchWorkflow,
      fromDate,
      toDate,
      status: workflowStatus,
    });

    const response = await get(`workflows-and-log/get?${queryParams}`);
    setWorkflowData(response);
  }, [offset, pageSize, orderBy, submitSearch]);

  useEffect(() => {
    if (submitSearch) {
      const pageReload = redirectUrl();
      history.push(pageReload);
    } else {
      getWorkflowsData();
    }
    setSubmitSearch(false);
    setRefresh(false);
  }, [getWorkflowsData, submitSearch, refresh]);

  const workflowsColumns = [
    {
      id: "expanded-chevron",
      header: "",
      size: 0,
      cell: (tableRow) => {
        const { expandedRowIds, row } = tableRow || {};
        const className = `lnr ${
          expandedRowIds?.includes(row.id)
            ? "lnr-chevron-down"
            : "lnr-chevron-right"
        }`;
        return (
          <div className="expanded_chevron">
            <i className={className} />
          </div>
        );
      },
    },
    {
      id: "alert",
      header: "",
      size: 0,
      cell: (tableRow) => {
        const { alertSet } = tableRow?.row?.original || {};
        return alertSet ? (
          <div
            className="bg-danger text-danger mb-0 rounded-circle mt-1"
            style={{
              height: "10px",
              width: "10px",
            }}
          />
        ) : null;
      },
    },
    {
      id: "wFNumber",
      enableSorting: true,
      header: "WF#",
      accessorFn: (row) => formatFixedDigits(row?.workflowNumber, 12),
    },
    {
      id: "wDate",
      enableSorting: true,
      header: "Start Date",
      accessorFn: (row) =>
        formatDate(row?.workflowStartDate, "MM/DD/YY hh:mm A"),
    },
    {
      id: "wType",
      enableSorting: true,
      header: "Type",
      accessorKey: "workflowType",
      size: 250,
    },
    {
      id: "reAccountNumber",
      enableSorting: true,
      header: "Account",
      accessorFn: (row) => row?.reAccountNumber || "-",
    },
    {
      id: "policyNumber",
      header: "Policy",
      accessorFn: (row) => row?.policyNumber || "-",
    },
    {
      id: "status",
      header: "Status",
      accessorFn: (row) => `${row?.status || "-"} / ${row?.subStatus || "-"}`,
    },
    {
      id: "actions",
      header: "",
      cell: (tableRow) => {
        const { workflowID } = tableRow?.row?.original || {};
        const navigateToEditPage = redirectUrl(workflowID);
        return (
          <div className="column-end pr-2 my-1">
            <Link to={navigateToEditPage}>
              <Badge className="btn-blue btn-badge align-middle">
                View / Edit
              </Badge>
            </Link>
          </div>
        );
      },
    },
  ];

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={isPending || isRequesting}>
      <Card className="pb-2">
        <CardBody className="py-2 bg-green">
          <WorkflowsTableSearch form={form} setSubmitSearch={setSubmitSearch} />
        </CardBody>
      </Card>
      <Table
        columns={workflowsColumns}
        data={data}
        renderNestedTable={WorkflowLogTable}
        pageProps={pageProps}
      />
    </Spinner>
  );
};

export default WorkflowsTable;

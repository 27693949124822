import { useCallback, useMemo } from "react";

import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const buildQueryParams = (params) => {
    const queryEntries = Object.entries(params) || [];
    if (queryEntries?.length) {
      const queryParts = queryEntries
        .filter(([_, value]) => value)
        .flatMap(([key, value]) =>
          Array.isArray(value)
            ? value.map(
                (item) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
              )
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      return queryParts.join("&");
    }
    return "";
  };

  const getQueryParams = useCallback(
    (queryParams) => {
      const stringTypeParams = typeof queryParams === "string";
      if (stringTypeParams) {
        return query.get(queryParams) || "";
      }

      return queryParams?.reduce((acc, param) => {
        const value = query.getAll(param);
        acc[param] = value.length > 1 ? value : value[0] || "";
        return acc;
      }, {});
    },
    [query]
  );

  return {
    getQueryParams,
    buildQueryParams,
  };
};

export default useQueryParams;

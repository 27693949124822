import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Container } from "reactstrap";

import {
  sanitizeValue,
  splitStringyRate,
} from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useQueryParams from "@hooks/useQueryParams";
import useToast from "@hooks/useToast";

import PIOForm from "../PIOForm/PIOForm";

const queryPath = ["from", "pageSize", "sortProductName", "sortCarrierName"];

const AddPIO = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { get, post } = useApiFetch();

  const { getQueryParams, buildQueryParams } = useQueryParams();
  const { validateRequiredField } = useToast();
  const [defaultData, setDefaultData] = useState(null);
  const [productData, setProductData] = useState({});

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const { productID, productName } = productData || {};

  const redirectToProducts = useMemo(() => {
    const queryParams = buildQueryParams({
      id: productID,
      page,
      ...restParams,
    });
    return `/products?${queryParams}`;
  }, [page, productID, restParams]);

  const getProduct = useCallback(async () => {
    const response = await get(`products/${id}`);
    setProductData(response);
  }, [id]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    if (productData) {
      setDefaultData({
        productID: id,
        productName,
        pioName: "",
        carrierPIOCode: "",
        pioMethod: "",
        pioDuration: "",
        pioCreditingRate: null,
        pioParticipationRate: null,
        pioMinRate: null,
        pioMaxRate: null,
        pioSelectable: true,
        status: "Active",
        fiaCapRate: null,
      });
    }
  }, [productName, id]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      pioName,
      carrierPIOCode,
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
    } = values || {};

    const isValid = validateRequiredField({
      "Investment Option": pioName,
      "Investment Option Code": carrierPIOCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      pioCreditingRate: splitStringyRate(pioCreditingRate),
      pioParticipationRate: splitStringyRate(pioParticipationRate),
      fiaCapRate: splitStringyRate(fiaCapRate),
      pioMinRate: splitStringyRate(pioMinRate),
      pioMaxRate: splitStringyRate(pioMaxRate),
    };
    post("products/pios", payloadValues, { redirectUrl: redirectToProducts });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Investment Option Detail</h3>
      <PIOForm
        data={defaultData}
        onSubmit={handleForm}
        redirectUrl={redirectToProducts}
      />
    </Container>
  );
};

export default AddPIO;
